import React, { useEffect, useState } from "react";
import {
  SimpleForm,
  SelectInput,
  Toolbar,
  SaveButton,
  Title,
  DeleteWithConfirmButton,
  ShowButton,
} from "react-admin";
import { useTranslate } from "ra-core";
import {
  Card,
  CircularProgress,
  Button,
  List,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Box,
  Alert,
} from "@mui/material";
import { useParams } from "react-router-dom";
import {
  useFetchData,
  getAsyncFetchData,
  deleteAsyncData,
  postAsyncData,
} from "../utils/useAdminApi";
import UserProgramParamsForm from "./UserProgramParamsForm";
import { ExpandMore } from "@mui/icons-material";

const validateName = [];
const validateFirstName = [];
const validateSubscribed = [];

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
    <DeleteWithConfirmButton undoable={false} />
  </Toolbar>
);

const keysToEdit = ["patientId", "name", "firstName"];
export const UserEdit = (props) => {
  const translate = useTranslate();
  const { programId, id } = useParams();
  const { data, loading, error } = useFetchData("/adminApi/user/" + id);
  const [userData, setUserData] = useState({});
  useEffect(() => {
    if (data) {
      const newUserData = {};
      keysToEdit.forEach((key) => {
        newUserData[key] = data[key];
      });
      setUserData(newUserData);
    }
  }, [data]);

  if (loading) {
    return <CircularProgress size={25} thickness={2} />;
  }
  if (!data || !userData) {
    return <CircularProgress size={25} thickness={2} />;
  }

  const PageTitle = () => {
    const { id } = useParams();
    return id ? (
      <Title
        title={translate("resources.users.title", {
          id: id,
        })}
      />
    ) : null;
  };

  const UserEditActions = () => (
    <Toolbar {...props}>
      <ShowButton
        to={`/${programId}/users/${id}/show`}
        resource="AlixMedUsers"
      />
    </Toolbar>
  );

  const handleChange = (e, key) => {
    setUserData({ ...userData, [key]: e.target.value });
  };

  const submitForm = async () => {
    const { error, data } = await postAsyncData(
      "/adminApi/user/" + id,
      userData
    );
  };

  const deleteUser = async () => {
    const { error, data } = await deleteAsyncData("/adminApi/user/" + id);
  };
  return (
    <>
      <PageTitle />
      <TextField
        label={"Id Patient"}
        variant="outlined"
        value={userData.patientId}
        onChange={(e) => handleChange(e, "patientId")}
        margin="normal"
      />
      <TextField
        label={"Nom"}
        variant="outlined"
        value={userData.name}
        onChange={(e) => handleChange(e, "name")}
        margin="normal"
      />
      <TextField
        label={"Prénom"}
        variant="outlined"
        value={userData.firstName}
        onChange={(e) => handleChange(e, "firstName")}
        margin="normal"
      />
      <Box sx={{ mt: 2, mb: 2 }}>
        <Button variant="contained" onClick={() => submitForm()} sx={{ mr: 1 }}>
          Valider
        </Button>
        <Button variant="outlined" onClick={() => deleteUser()} sx={{ mr: 1 }}>
          Supprimer
        </Button>
      </Box>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>{translate(`resources.users.programParams`)}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UserProgramParamsForm user={data} />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default UserEdit;
