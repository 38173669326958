import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import {
  useGetOne,
  useRedirect,
  RecordContextProvider,
  Title,
} from 'react-admin';
import { useTranslate } from "ra-core";
import {
  Box,
  CircularProgress
} from '@mui/material';
import { AlixMedInviteCode } from "../API";
import InviteCodeDisplay from './InviteCodeDisplay';

const CodeTitle = () => {
  const translate = useTranslate();
  const { id } = useParams();
  return id ? (
    <Title title={translate('resources.codes.title', {
      id: id,
    })} />
  ) : null;
};

export const CodeProgramShow = (props) => {
  const { programId, id } = useParams();
  const redirect = useRedirect();
  const { data, isLoading } = useGetOne(
    'AlixMedInviteCodes',
    { id },
    { onError: () => redirect(`/${programId}`) }
  );
  if (isLoading) { return <CircularProgress size={25} thickness={2} />; }

  return (
    <RecordContextProvider value={data}>
      <CodeTitle />
      <Box sx={{ mt: 4 }}>
        <InviteCodeDisplay data={data} />
      </Box>
    </RecordContextProvider>);
};

export default CodeProgramShow;