import React, { useState } from "react";
import {
  List,
  SelectInput,
  TextInput,
  useListContext,
  ListContextProvider,
  useGetList,
} from "react-admin";
import {
  Chip,
  Grid,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from '@mui/material';
import { AmplifyFilter } from "react-admin-amplify";

const intensities = {
  0: { name: "Low", color: "primary" },
  1: { name: "Medium", color: "info" },
  2: { name: "High", color: "warning" },
  3: { name: "Very High", color: "error" },
};

const defaultQuery = "listAlixMedExercises";

const ExerciseFilter = (props) => (
  <AmplifyFilter {...props} defaultQuery={defaultQuery}>
    <TextInput source="listAlixMedExercisesByName.name" label="Name" alwaysOn resettable />
    <SelectInput
      source="listAlixMedExercisesByIntensity.intensity"
      label="Intensity"
      choices={[
        { id: 0, name: intensities[0].name },
        { id: 1, name: intensities[1].name },
        { id: 2, name: intensities[2].name },
        { id: 3, name: intensities[3].name },
      ]}
      resettable
      alwaysOn
    />
    {/* <SelectInput
      source="exercisesByCategories.categories"
      label="Categories"
      choices={[
        { id: "C", name: "Cardio" },
        { id: "M", name: "Muscle-development" },
        { id: "P", name: "Proprioception" },
        { id: "S", name: "Stretching" },
        { id: "W", name: "Warmup" },
      ]}
      alwaysOn
      resettable
    /> */}
  </AmplifyFilter>
);

const ExerciseList = (props) => {
  const [query, setQuery] = useState(defaultQuery);

  const { data, total, isLoading, error } = useGetList(
    'AlixMedExercises',
    {
      pagination: {  page: 1, perPage: 10  },
    }
  );

  if (isLoading) { return <CircularProgress size={25} thickness={2} />; }
  if (error) { return <p>ERROR</p>; }
  console.log("data",data)
  return (
    <ListContextProvider value={{ data: data || [], total: total || 0 }}>
      <List
        sort={{ field: 'name', order: 'ASC' }}
        perPage={1000}
        pagination={false}
        component="div"
        actions={false}
        filters={<ExerciseFilter setQuery={setQuery} />}
        resource="AlixMedExercises"
        title="Exercices"
      >
        <ExerciseGrid />
      </List>
    </ListContextProvider>

  );
};

const ExerciseGrid = () => {
  const { data, isLoading } = useListContext();
  if (isLoading) {
    return null;
  }
  console.log("data", data);
  return (
    <Grid container spacing={2} sx={{ marginTop: '1em' }}>
      {data.map(record => (
        <Grid container key={record.id} xs={12} sm={6 * record.variants.length} md={4 * record.variants.length} lg={3 * record.variants.length} xl={2 * record.variants.length} item>
          <Card sx={{ width: 1 }}>
            <Grid container spacing={2}>

              {record.variants.map((variant, i) => (
                <Grid item key={i} xs={record.variants.length == 2 ? 6 : 12}>
                  <img
                    src={`/gif/${variant.id}.gif`}
                    className="exercise-image"
                    style={{ width: "100%", aspectRatio: "1/1" }}
                  />
                </Grid>
              )
              )}
            </Grid>

            <CardContent sx={{ paddingBottom: '0.5em' }}>
              <Typography variant="body1" color="textPrimary" component="p">
                {record.name}
              </Typography>
              <Typography variant="body1" color="primary" component="div">
                <Chip
                  label={record.categories.map((item) => ' ' + item.id + ' ')}
                />
                &nbsp;
                <Chip
                  label={intensities[record.intensity].name}
                  color={intensities[record.intensity].color}
                />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default ExerciseList;