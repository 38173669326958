import React, { useState } from "react";
import JSONPretty from 'react-json-prettify';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import { useTranslate } from "ra-core";


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function RawSessionOutputDialog(props) {
  const session = props.session;
  const [open, setOpen] = useState(false);
  const translate = useTranslate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid container justifyContent="flex-end">
      <IconButton onClick={handleClickOpen}>
        <IntegrationInstructionsIcon />
      </IconButton>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {translate(`resources.sessions.sessionData`)}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{p:2}}>
          <JSONPretty json={session} padding={2} />
        </Box>
      </Dialog>
    </Grid>
  );

}