import { useEffect, useState } from "react";
import { useTranslate } from "ra-core";
import {
  CircularProgress,
  Button,
  Typography,
  Box,
  Checkbox,
} from '@mui/material';
import { useFetchData, getAsyncFetchData, deleteAsyncData,  postAsyncData } from "../utils/useAdminApi";

export const UserProgramParams = (props) => {
  console.log("UserProgramParams props", props)
  const translate = useTranslate();
  const { data: dataProgram, loading: loadingProgram, error: errorProgram } = useFetchData("/adminApi/program/"+props.user.program)
  const { data, loading, error } = useFetchData("/adminApi/user/"+props.user.id+"/programParams")
  const [showDetails, setShowDetails] = useState(false)
  
  if (loading || loadingProgram) { return <CircularProgress size={25} thickness={2} />; }
  if (!data || !dataProgram || errorProgram || error) { return <CircularProgress size={25} thickness={2} />; }
  console.log("UserProgramParams dataProgram", dataProgram)

  const createNewAnswer = (answers) => {
    console.log("VALIDATING ANSWER", answers)
    const timestamp = new Date()
    const params = {
      id: props.user.id+timestamp,
      userId: props.user.id,
      timestamp: timestamp,
      questions: answers,
      status: "active"
    }
    postAsyncData("/adminApi/user/"+props.user.id+"/programParams", params)
  }

  if(!data.length){
    return(
        <>
            {showDetails ? 
            <>
              <UserProgramParamsForm
                  questions={dataProgram.questionnaire}
                  answers={dataProgram.questionnaire}
                  validate={createNewAnswer}
              ></UserProgramParamsForm>
            </>
            :  
            <>
              <Typography>Aucune réponse trouvée</Typography>
              <Button variant="contained" onClick={() => setShowDetails(true)}>
                Créer une réponse
              </Button>  
            </>
            }
        </>
    )
  }
  return (
    <>
    </>
  );
   
}

const UserProgramParamsForm = (props) => {
    let updatedAnswers = props.answers
    console.log(updatedAnswers)
    return(
      <>
      {
          props.questions.map((question, i) => {
            switch(question.type){
                case "EnumMultiple": {  
                    return(
                      <>
                      <Typography>QUESTION N° {i+1} : {question.questionId}</Typography>
                        {question.enum && question.enum.map((value) => {
                          return(
                            <Box display="flex" flexDirection="row">
                              <Typography>{value}</Typography>
                              <Checkbox
                              checked={updatedAnswers[i].answers && updatedAnswers[i].answers.includes(value)}
                              onChange={(e) => {
                                if(updatedAnswers[i].answers){
                                  if(e.target.checked){
                                    updatedAnswers[i].answers.push(value)
                                  } else {
                                    updatedAnswers[i].answers = updatedAnswers[i].answers.filter((v) => v != value)
                                  }
                                }else if (e.target.checked){
                                  updatedAnswers[i].answers = [value]
                                }
                              }}
                              color="primary"
                              disabled={false}
                              />
                            </Box>
                          )}
                        )}
                      </>
                    )
                }
            }
        })
      }
      <Button variant="contained" onClick={() => props.validate(updatedAnswers)}>
         Valider
      </Button>
      </>
      
    )
}

export default UserProgramParams;