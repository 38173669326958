import React, { useState } from "react";
import {
  List,
  Pagination,
  Datagrid,
  DateField,
  EditButton,
  ShowButton,
  TextField,
  FunctionField,
  TextInput,
  SimpleShowLayout,
  ReferenceManyField,
  useGetList,
  useGetOne,
  ListContextProvider,
  useGetManyReference,
  Title,
} from "react-admin";
import {
  Card,
  CircularProgress,
  Toolbar,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { AmplifyFilter } from "react-admin-amplify";
import { useGetMany, useSafeSetState, useTranslate } from "ra-core";
import InviteCodeDisplay from './InviteCodeDisplay';
import { Stack } from '@mui/material';

const defaultQuery = "listInviteCodes";


const InviteCodesFilter = (props) => {
  const translate = useTranslate();
  return (
  <AmplifyFilter {...props} defaultQuery={defaultQuery}>
    <TextInput source="listInviteCodesByRefKey.name" label={translate(`resources.codes.refKey`)} alwaysOn resettable />
    <TextInput source="listInviteCodesByName.name" label="Nom" alwaysOn resettable />
  </AmplifyFilter>
)
  };

const InviteCodesByProgramId = (props) => {
  const { programId } = useParams();
  const [query, setQuery] = useState(defaultQuery);
  const translate = useTranslate();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const sort = { field: 'id', order: 'ASC' };
  const dataProgram = useGetOne(
    'AlixMedPrograms',
    {id : programId}
  );

  const { data, total, isLoading, error } = useGetManyReference(
    'AlixMedInviteCodes',
    {
      target: 'program',
      id: programId,
      pagination: { page, perPage },
    }
  );
  if (isLoading) { return <CircularProgress size={25} thickness={2} />; }
  if (error) { return <p>ERROR</p>; }

  const InviteCodesTitle = () => {
    return (
      <Title title={translate(`pos.codeListTitle`, { programName: dataProgram?.data?.displayName })} />
    );
  };

  return data ? (
    <>
      <InviteCodesTitle />
      <ListContextProvider value={{ data: data || [], total: total || 0, page, perPage, setPage, setPerPage, sort }}>
        <List
          filters={<InviteCodesFilter setQuery={setQuery} />}
          actions={false}
          perPage={perPage}
          pagination={<ListPagination />}
          resource="AlixMedInviteCodes"
          sx={{ mt: 6 }}
          title=" "
        >
          <Datagrid
            data={data}
            sort={sort}
            bulkActionButtons={false}
          >
            <TextField source="name" label="Nom" sortable={false} />
            <TextField source="id" label="Token" sortable={false} />
            <TextField source="uses" label="Nombre d'utilisations" sortable={false} />
            <FunctionField render={record => <ShowButton to={`/${programId}/invite-codes/${record.id}/show`} resource="AlixMedInviteCodes" />} />
            <FunctionField render={record => <EditButton to={`/${programId}/invite-codes/${record.id}/edit`} resource="AlixMedInviteCodes" />} />
          </Datagrid>
        </List>
      </ListContextProvider>
    </>
  ) : null;
};

export const CodeProgramList = () => {
  return (
    <InviteCodesByProgramId />
  );
};
const ListPagination = (props) => (<Pagination rowsPerPageOptions={[50, 100, 200, 500]} {...props} />);
export default CodeProgramList;