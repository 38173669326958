import { TranslationMessages } from 'react-admin';
import frenchMessages from 'ra-language-french';

const customFrenchMessages: TranslationMessages = {
    ...frenchMessages,
    pos: {
        programShowTitle: '%{programName}',
        codeListTitle: '%{programName} – codes d\'invitation',
        usersListTitle: '%{programName} – patients',
        search: 'Rechercher',
        configuration: 'Configuration',
        language: 'Langue',
        dashboard: {
            title: '',
        },
        menu: {
            catalog: 'Catalogue',
            sessions: 'Séances',
            codes: 'Codes d\'invitation',
            users: 'Patients',
        },
    },
    resources: {
        exercises: {
            name: 'Exercices'
        },
        sessions: {
            name: 'Séances',
            generate: 'Générer',
            execute: 'Exécuter',
            generateSessions: 'Générer des séances',
            iterations: 'Itérations',
            randomization: 'Randomisation',
            user: 'Patient',
            userGeneralParams: 'Séance et niveau',
            userPathologyParams: 'Pathologie',
            userProfile: 'Profil',
            equipments: 'Équipements',
            painAreas: 'Zones de douleur',
            module: 'Module',
            level: 'Niveau',
            healthgoal: 'Objectif de santé',
            duration: 'Durée',
            author: 'Auteur',
            mode: 'Mode',
            full: 'Complet',
            full_batch: 'Complet (batch)',
            exampleAccount: 'Compte exemple :',
            warmup: 'Échauffements',
            exercises: 'Exercices',
            stretching: 'Étirements',
            switchSide: 'changement de côté',
            sessionData: 'Données de la séance',
            sessionModule: 'Séance %{module}',
            bodyPartToWorkOn: 'Partie du corps à travailler',
            fatigueLevel: 'Niveau de fatigue',
            sessionNumber: 'Numéro de séance',
            enduranceScore: 'Score d\'endurance',
            pasScore: 'Score PAS',
            healthgoalScore: 'Score objectif santé',
            levelType: 'Type de niveau',
            obesityCalculationMode: 'Mode de calcul',
            bodyFatPercentage: 'Indice de masse grasse (%)',
            obesityFatRatioEvolution: 'Masse grasse',
            obesityMuscleRatioEvolution: 'Masse musculaire',
            indoorSession: 'Renforcement musculaire',
            walkSession: 'Marche',
        },
        equipments: { // Keep snake case
            name: 'Équipements',
            pull_up_bar: 'Barres de traction',
            dumbbell: 'Haltères',
            stepups_box: 'Box Step-Up',
            bike: 'Vélo d\'intérieur',
            kettlebell: 'Kettlebell',
            resistance_band: 'Bandes de résistance',
            ankle_wrist_weights: 'Poids légers',
            medicine_ball: 'Medicine ball',
            dips_bar: 'Barres parallèles',
            swiss_ball: 'Swiss ball',
            tennis_ball: 'Balle de tennis',
            waff: 'Coussin d\'équilibre',
            water_bottle: 'Gourdes/bouteilles',
            stool: 'Tabouret',
            chair: 'Chaise',
            chair_with_armrests: 'Chaise avec accoudoirs',
            table: 'Table',
            weighted_backpack: 'Gilet lesté',
            pillar: 'Pilier',
            wall: 'Mur',
            towels: 'Serviettes',
            musculation_bench: 'Banc de musculation',
        },
        codes: {
            listName: 'Liste',
            title: 'Code d\'invitation %{id}',
            id: 'Identifiant',
            refKey: 'Clé de référence patient',
            status: 'Statut',
            available: 'Actif',
            used: 'Utilisé',
            expired: 'Expiré',
            revoked: 'Révoqué',
            uses: 'nombre d\'utilisations',
            createdAt: 'Créé le',
            statusLabel: 'Status : ',
            expiresAtLabel: 'Date d\'expiration : ',
            expiresAt: 'Date d\'expiration',
            idLabel: 'Id : ',
            validation: 'Validation',
            validationLabel: 'Validation : ',
            validationMethod: 'Méthode de validation',
            seeQrCode: 'Voir le QR code',
            urlToCopy: "URL à copier",
            inClipboard: 'Copié !',
            copyToClipboardError: 'Erreur dans la copie dans le presse-papier.',
            use: {
                label: 'Usage : ',
                count: 'Décompte d\'utilisation',
                rest: ' restants sur ',
                unlimited: 'Illimité',
            },
            edit: {
                setMaxUses: 'Nombre d\'utilisation maximale',
                setExpiresAtDate: 'Définir une date d\'expiration',
                rest: ' restants sur ',
                unlimited: 'Illimité',
            },
        },
        programs: {
            name: '%{name}',
            demo: '%{name}',
            incredible: '%{name}',
            all: 'Tous',
        },
        painAreas: {
            right_shoulder: "Épaule droite",
            left_shoulder: "Épaule gauche",
            right_elbow: "Coude droit",
            left_elbow: "Coude gauche",
            right_wrist: "Poignet droit",
            left_wrist: "Poignet gauche",
            right_hip: "Hanche droite",
            left_hip: "Hanche gauche",
            right_knee: "Genou droit",
            left_knee: "Genou gauche",
            right_ankle: "Cheville droite",
            left_ankle: "Cheville gauche",
            upper_back: "Haut du dos",
            lower_back: "Bas du dos"
        },
        generalBodyParts: {
            lower_body: 'Bas du corps',
            upper_body: 'Haut du corps',
            lower_back: 'Bas du dos',
        },
        fatigueLevels: {
            inShape: 'En forme',
            littleTired: 'Un peu fatigué',
            veryTired: 'Très fatigué'
        },
        obesityCalculationModes: {
            bmi: "IMC",
            connectedScale: "Balance connectée",
        },
        obesityRatioEvolutions: {
            increasing: "En augmentation",
            decreasing: "En baisse",
        },
        modules: {
            name: 'Modules',
            balance: 'Balance',
            endurance: 'Endurance',
            power: 'Puissance',
            resistance: 'Résistance',
            low: 'Low (0)',
            medium: 'Medium (1)',
            high: 'High (2)',
            veryHigh: 'Very High (3)'
        },
        healthgoals: {
            fit: 'Fit',
            ven: 'Respiration',
            sle: 'Sommeil',
            msd: 'TMS',
            obe: 'Poids',
        },
        users: {
            name: 'Patients',
            title: 'Patient %{patientId}',
            email: 'Courriel',
            phoneNumber: 'Numéro de téléphone',
            age: 'Âge',
            gender: 'Genre',
            female: 'Femme',
            male: 'Homme',
            nonbinary: 'Non-binaire',
            id: 'Identifiant',
            firstname: 'Prénom',
            lastname: 'Nom',
            patientId: 'Id patient',
            createdAt: 'Créé le',
            membershipStartedAt: 'Programme rejoint le',
            nickname: 'Pseudo',
            dateOfBirth: 'Date de naissance',
            height: 'Taille (cm)',
            weight: 'Poids (kg)',
            programParams: 'Questions de paramétrage',
            sessions: {
                title: 'Séances',
                sessionOf: 'Séance du ',
                walkSessionOf: 'Séance de marche du %{date}',
                indoorSessionOf: 'Séance indoor du %{date}',
                sessionNumberOf: 'Séance n°%{count} du %{date}',
                walkSessionNumberOf: 'Séance de marche n°%{count} du %{date}',
                indoorSessionNumberOf: 'Séance indoor n°%{count} du %{date}',
                sessionWithSensor: 'Séance réalisée avec capteur : ',
                data: 'Données',
                heartBeat: 'Rythme cardiaque',
                bpm: 'BPM',
                min: 'Min.',
                avd: 'Moyen',
                max: 'Max.',
                exerciseDuration: 'Durée',
                averagePace: 'Allure (min/km)',
                averageSpeed: 'Vitesse (km/h)',
                distance: 'Distance (m)',
                walkEasy: 'Facile',
                walkMedium: 'Moyenne',
                walkHard: 'Difficile',
                walkAcceleration: 'Accélération',
                walkVeryHard: 'À fond',
                rating: 'Évaluation',
                seeMore: 'Voir plus de séances',
            },
            charts: {
                stepsTitle: 'Nombre de pas',
                activityTitle: 'Temps d\'activité',
                stepsLegend: 'Nombre de pas par jour',
                activityLegend: 'Temps d\'activité journalier',
                unitSteps: ' pas',
                unitActivity: ' min.',
            },
            null: '-',
            eventLogList: 'Événements du compte',
        },
        sessionGenerator: {
            showValidSessions: 'Afficher les séances valides.',
            showExercisesSectionList: 'Afficher le contenu de la section des Exercices',
            incorrectPauseBefore: 'Durée de pause incorrecte avant `%{exerciseVariantId}` dans la section %{section}. Devrait être %{validPause} s mais %{foundPause} trouvé.',
            unilateralVariantExerciseNotPaired: "L'exercice `%{exerciseVariantId}` dans la section %{section} est unilatéral mais n'est pas associé à sa variante jumelle.",
            unknownVariantId: "Identifiant de variante d'exercice inconnu : `%{exerciseVariantId}`",
            unsatisfiedEquipmentWithNoUserEquipments: "L'exercice `%{exerciseVariantId}` requiert l'équipement `%{requiredEquipment}`. Mais le patient ne dispose d'aucun équipement.",
            unsatisfiedEquipment: "L'exercice `%{exerciseVariantId}` requiert l'équipement `%{requiredEquipment}`. Mais le patient ne dispose que des équipements suivants : %{userEquipments}",
            incorrectNumberOfComponentInExercisesSection: "Le nombre de composants dans la section d'exercices est incorrect. Il doit être égal à 10 fois le nombre d'itérations. Nombre attendu : %{expectedNumberOfComponents}. Nombre trouvé : %{foundNumberOfComponents}",
            incorrectExerciseDuration: 'Le composant `%{exerciseVariantId}` dans la section %{section} devrait durer %{expectedDuration} s. Trouvé : %{foundDuration}.',
            unsatisfiedPainAreaCondition: "L'exercice `%{exerciseVariantId}` dans la section %{section} sollicite des zones pour lesquels le patient a indiqué avoir mal : %{painAreas}.",
            minDurationNotReached: "La séance n'atteint pas la durée minimale requise de %{expectedMinDuration} s. Trouvé : %{foundDuration} s.",
        },
        misc: {
            none: 'Aucun',
            error: 'Erreur |||| Erreurs',
            success: 'Succès',
            noData: 'Aucune donnée',
            close: 'Fermer',
            yes: 'Oui',
            no: 'Non',
            total: 'Total',
        }
    },
    warning: {
        phone_prefix: "Veuillez ajouter l'indicatif pays dans le numéro recherché (ex: +336XXXXXXXX)"
    },
    errors: {
        user: "Erreur: Patient non trouvé"
    }
};

export default customFrenchMessages;
