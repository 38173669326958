import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import React from "react";
import {
  Title,
  List,
  useGetList,
} from "react-admin";
import { useTranslate } from "ra-core";
import { Stack, CircularProgress, Grid } from '@mui/material';
import ProgramDisplay from './ProgramDisplay';

export default function Dashboard(props) {
  const sort = { field: 'id', order: 'ASC' };
  const { data, isLoading } = useGetList('AlixMedPrograms', {
    sort
  });
  if (isLoading) { return <CircularProgress size={25} thickness={2} />; }

  return (
    <>
      <Grid
        justifyContent="center"
        alignItems="flex-start"
        container
        sx={{ minHeight: "100vh" }}
      >
        <Grid lg={8}>
          <Stack
            spacing={2}
            direction="column"
            sx={{ mx: 4, mt: 6 }}
          >
            {data ? data.map((program) => (
              <ProgramDisplay data={program} key={program.id} />
            )) : null}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
