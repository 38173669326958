import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Button,
  TextField,
  Box,
  IconButton
} from '@mui/material';
import {
  EditButton,
  BulkUpdateButton,
  FunctionField,
} from "react-admin";
import { useTranslate } from "ra-core";
import Moment from 'react-moment';
import 'moment/locale/fr';
import QRCode from "react-qr-code";
import QrCodeIcon from '@mui/icons-material/QrCode';
import FileCopyIcon from '@mui/icons-material/FileCopy';

export default function InviteCodeDisplay(props) {
  const translate = useTranslate();
  const code = props.data
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const [copySuccess, setCopySuccess] = useState('');

  const url = `https://launcher.med.alixforme.com/invite/${code.id}`

  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(url).then(() => {
      setCopySuccess(translate(`resources.codes.inClipboard`));
      setTimeout(() => setCopySuccess(''), 2000);
    }, () => {
      setCopySuccess(translate(`resources.codes.copyToClipboardError`));
    });
  };

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Dialog
          open={open}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Stack
              direction="column"
              justifyContent="center"
              spacing={1}>
              <QRCode
                size={256}
                style={{ height: "auto", width: "100%", borderStyle: "solid", borderColor: "#FFF" }}
                value={url}
                viewBox={`0 0 256 256`}
              />
              <Box display="flex" alignItems="center" gap={0}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label={translate(`resources.codes.urlToCopy`)}
                  value={url}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <IconButton
                  color="primary"
                  onClick={copyToClipboard}
                >
                  <FileCopyIcon />
                </IconButton>
              </Box>
              {copySuccess && <Typography color="success.main">{copySuccess}</Typography>}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} autoFocus>{translate(`resources.misc.close`)}</Button>
          </DialogActions>
        </Dialog>
        <Typography variant="h6">
          {code.name} <Typography variant="subtitle1">({code.program})</Typography>
        </Typography>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={4}
          sx={{ mt: 2, mb: 2 }}>
          <Button onClick={handleClick} variant="outlined" startIcon={<QrCodeIcon />}>{translate(`resources.codes.seeQrCode`)}</Button>
        </Stack>
        <Typography variant="body2">
          {translate(`resources.codes.statusLabel`)}<Typography variant="body1" component="span">{code.codeStatus}</Typography>
        </Typography>
        <Typography variant="body2">
          {translate(`resources.codes.expiresAtLabel`)}<Typography variant="body1" component="span">{code.withExpiration ? (<Moment unix locale="fr" format="D MMMM YYYY à H:mm:ss">{code.expiresAt / 1000}</Moment>) : "Non"}</Typography>
        </Typography>
        <Typography variant="body2">
          {translate(`resources.codes.use.label`)}<Typography variant="body1" component="span">{code.withCounts ? (
            <>{code.maxUses - code.uses}{translate(`resources.codes.use.rest`)}{code.maxUses}</>
          ) : translate(`resources.codes.use.unlimited`)}</Typography>
        </Typography>
        <Typography variant="body2">
          {translate(`resources.codes.validationLabel`)}<Typography variant="body1" component="span">{code.withUserValidation ? translate(`resources.misc.yes`) : translate(`resources.misc.no`)}</Typography>
        </Typography>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          sx={{ mt: 2 }}>
          <FunctionField render={record => <EditButton label="Éditer les paramètres" to={`/${record.program}/invite-codes/${record.id}/edit`} resource="AlixMedInviteCodes" record={code} />} />
          <BulkUpdateButton
            disabled={code.status === "revoked" ? true : false}
            label="Révoquer"
            data={{ status: "revoked" }}
            sx={{ color: "red" }}
          />
        </Stack>
      </CardContent>
    </Card>
  );
}