import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from '@aws-amplify/api';
import { getAsyncFetchData } from "../utils/useAdminApi";
import moment from 'moment';
import TimerIcon from '@mui/icons-material/Timer';
import PersonIcon from '@mui/icons-material/Person';
import ExtensionIcon from '@mui/icons-material/Extension';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import PendingIcon from '@mui/icons-material/Pending';
import CircleIcon from '@mui/icons-material/Circle';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import ElderlyIcon from '@mui/icons-material/Elderly';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { generateSession } from '../graphql/mutations';
import { listAlixMedExercises } from '../graphql/queries';

import {
  BooleanInput,
  NumberInput,
  RadioButtonGroupInput,
  required,
  SelectArrayInput,
  SelectInput,
  TextInput,
  Title,
  Form,
} from "react-admin";
import { useSafeSetState, useTranslate } from "ra-core";
import {
  Avatar,
  Alert,
  AlertTitle,
  Button,
  Chip,
  Grid,
  Card,
  CardContent,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  Box,
  useMediaQuery,
  Theme,
  CircularProgress,
  Slider,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@mui/material';
import { useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import RawSessionOutputDialog from './RawSessionOutputDialog';

const SessionGenerator = (props) => {
  enum CheckResultStatus {
    NoInfo,
    Pending,
    Success,
    Error
  }
  const translate = useTranslate();
  const [loading, setLoading] = useSafeSetState(false);
  const [showValidSessions, setShowValidSessions] = useSafeSetState(false);
  const [showExercisesSectionList, setShowExercisesSectionList] = useSafeSetState(false);
  const [numberOfLoadedSessions, setNumberOfLoadedSessions] = useSafeSetState(0);
  const [numberOfSessionToGenerateInBatchMode, setNumberOfSessionToGenerateInBatchMode] = useState<number>(250);
  const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
  const [sessions, setSessions] = useState<any>([]);
  const [program, setProgram] = useState<any>({});
  const [exercisesVariantsList, setExercisesVariantsList] = useState<any>([]);
  const { programId } = useParams();

  useEffect(() => {
    const fetchProgram = async () => {
      const response = await getAsyncFetchData("/adminApi/program/" + programId);
      if (response.error) {
        console.error('Failed to fetch program');
        return;
      }
      const data = response.data;
      console.log("program", data)
      setProgram(data);
    };
    fetchProgram()
  }, [])

  const submit = async (values) => {
    setLoading(true);
    setNumberOfLoadedSessions(0);
    let generatedSessions: any[] = [];
    let params;
    let today = new Date();
    let sessionParamsToTest: any = [];

    if (values.mode == "user_id") { // user_id mode
      params = values.params_user_id;
    } else { // full_single or full_batch mode
      params = values.params_full;

      let dateOfBirth = new Date(today.valueOf() - parseInt(params.dateOfBirth) * 366 * 86400000);
      params.dateOfBirth = dateOfBirth.toISOString();

      // moduleLevel to float
      params.moduleLevel = parseInt(params.moduleLevel);

      // Sessions completed (sessions completed = sessions number - 1)
      params.sessionsCompleted = params.sessionsCompleted - 1
    }

    // Load exercises database if not already available
    let allExercises;
    if (exercisesVariantsList.length == 0) {
      const exercisesData: any = await API.graphql(graphqlOperation(listAlixMedExercises, { limit: 1000 }));

      if (typeof (exercisesData.data) !== "undefined" && typeof (exercisesData.data.listAlixMedExercises) !== "undefined" && typeof (exercisesData.data.listAlixMedExercises.items) !== "undefined") {
        allExercises = exercisesData.data.listAlixMedExercises.items.reduce((object, item) => {
          item.variants.map((variant) => {
            object[variant.id] = item;
          });
          return object;
        }, {});

        setExercisesVariantsList(allExercises);
      }
    } else {
      allExercises = exercisesVariantsList;
    }

    let sessionResult: any = null;

    let numberOfSessionsToGenerate: any = 1;
    if (values.mode == "full_batch") {
      if (sessionParamsToTest.length > 0) {
        numberOfSessionsToGenerate = sessionParamsToTest.length * 12;
        setNumberOfSessionToGenerateInBatchMode(numberOfSessionsToGenerate);
      } else {
        numberOfSessionsToGenerate = numberOfSessionToGenerateInBatchMode;
      }
    }

    // Insert program id
    params.program = programId;

    for (let i = 0; i < numberOfSessionsToGenerate; i++) {
      if (values.mode == "full_batch") {
        // Randomize some parameters
        if (values.randomize.moduleLevel) {
          params.moduleLevel = parseInt(levelList[Math.floor(Math.random() * levelList.length)].id);
        }
        if (values.randomize.module) {
          params.module = moduleList[Math.floor(Math.random() * moduleList.length)].id;
        }

        if (values.randomize.gender) {
          params.gender = genderList[Math.floor(Math.random() * genderList.length)].id;
        }
        params.iterations = 1; //Math.floor(Math.random() * 6 + 1);
        if (values.randomize.dateOfBirth) {
          let dateOfBirth = new Date(today.valueOf() - Math.floor(Math.random() * 62 + 18) * 366 * 86400000);
          params.dateOfBirth = dateOfBirth.toISOString();
        }

        if (values.randomize.equipments) {
          // Select up to 6 equipments (with 2 more chances to have 0 equipments)
          let numberOfEquipments = Math.floor(Math.random() * 8 - 1)
          numberOfEquipments = numberOfEquipments < 0 ? 0 : numberOfEquipments
          params.equipments = [];
          for (let j = 0; j < numberOfEquipments; j++) {
            params.equipments.push(equipementList[Math.floor(Math.random() * equipementList.length)].id)
          }
          params.equipments = params.equipments.filter((val, index, arr) => arr.indexOf(val) === index) // Deduplicates equipments
        }

        if (values.randomize.painAreas) {
          // Select up to 4 pain areas (with 50% chances to have 0 pain area, 12.5% for 1 or 2 or 3 or 4 pain areas)
          let numberOfPainAreas = Math.floor(Math.random() * 8 - 3)
          numberOfPainAreas = numberOfPainAreas < 0 ? 0 : numberOfPainAreas
          params.painAreas = [];
          for (let j = 0; j < numberOfPainAreas; j++) {
            params.painAreas.push(painAreaList[Math.floor(Math.random() * painAreaList.length)].id)
          }
          params.painAreas = params.painAreas.filter((val, index, arr) => arr.indexOf(val) === index) // Deduplicates pain areas
        }

        // Randomize program params for kprep program
        //TODO have a general process to randomize questionnaire answers through all programs
        if (params.program === "kprep") {
          const extensionValues = [-10, 0, 4, 14]
          const flexionValues = [-10, 360, 140, 100, 80]
          const kneeValues = ["left", "right"]
          const walkingTestDurationValues = [0, 4, 8, 12, 20]
          params.programParams = {
            extension: extensionValues[Math.floor(Math.random() * extensionValues.length)],
            flexion: flexionValues[Math.floor(Math.random() * flexionValues.length)],
            knee: kneeValues[Math.floor(Math.random() * kneeValues.length)],
            walkingTestDuration: walkingTestDurationValues[Math.floor(Math.random() * walkingTestDurationValues.length)],
          }
        }
      }
      try {
        sessionResult = await API.graphql(graphqlOperation(generateSession, { input: params }));
      } catch (err) {
        let jsonErr: any = err;
        generatedSessions.push({
          'params': Object.assign({}, params),
          'result': {},
          'status': CheckResultStatus.Error,
          'errorMessages': [JSON.stringify(jsonErr)]
        })
        continue;
      }

      if (sessionResult && typeof (sessionResult.data) !== "undefined" && typeof (sessionResult.data.generateSession) == "object") {
        generatedSessions.push({
          'params': Object.assign({}, params),
          'result': sessionResult.data.generateSession,
          'status': CheckResultStatus.Success,
          'errorMessages': {}
        })
      } else {
        generatedSessions.push({
          'params': Object.assign({}, params),
          'result': {},
          'status': CheckResultStatus.Error,
          'errorMessages': ['No data or inconsistant data from server.']
        })
      }

      setNumberOfLoadedSessions(i + 1);
    }

    setSessions(generatedSessions);
    setLoading(false);
  };

  const moduleList = [
    { id: 'balance', name: translate(`resources.modules.balance`) },
    { id: 'endurance', name: translate(`resources.modules.endurance`) },
    { id: 'power', name: translate(`resources.modules.power`) },
    { id: 'resistance', name: translate(`resources.modules.resistance`) },
  ];

  const levelList = [
    { id: '0', name: translate(`resources.modules.low`) },
    { id: '1', name: translate(`resources.modules.medium`) },
    { id: '2', name: translate(`resources.modules.high`) },
    { id: '3', name: translate(`resources.modules.veryHigh`) },
  ];

  const genderList = [
    { id: 'female', name: translate(`resources.users.female`) },
    { id: 'male', name: translate(`resources.users.male`) },
  ];

  const equipementList = [
    { id: 'pull_up_bar', name: translate(`resources.equipments.pull_up_bar`) },
    { id: 'dumbbell', name: translate(`resources.equipments.dumbbell`) },
    { id: 'stepups_box', name: translate(`resources.equipments.stepups_box`) },
    { id: 'bike', name: translate(`resources.equipments.bike`) },
    { id: 'kettlebell', name: translate(`resources.equipments.kettlebell`) },
    { id: 'resistance_band', name: translate(`resources.equipments.resistance_band`) },
    { id: 'ankle_wrist_weights', name: translate(`resources.equipments.ankle_wrist_weights`) },
    { id: 'medicine_ball', name: translate(`resources.equipments.medicine_ball`) },
    { id: 'dips_bar', name: translate(`resources.equipments.dips_bar`) },
    { id: 'swiss_ball', name: translate(`resources.equipments.swiss_ball`) },
    { id: 'tennis_ball', name: translate(`resources.equipments.tennis_ball`) },
    { id: 'waff', name: translate(`resources.equipments.waff`) },
    { id: 'water_bottle', name: translate(`resources.equipments.water_bottle`) },
    { id: 'stool', name: translate(`resources.equipments.stool`) },
    { id: 'chair', name: translate(`resources.equipments.chair`) },
    { id: 'chair_with_armrests', name: translate(`resources.equipments.chair_with_armrests`) },
    { id: 'table', name: translate(`resources.equipments.table`) },
    { id: 'weighted_backpack', name: translate(`resources.equipments.weighted_backpack`) },
    { id: 'pillar', name: translate(`resources.equipments.pillar`) },
    { id: 'wall', name: translate(`resources.equipments.wall`) },
    { id: 'towels', name: translate(`resources.equipments.towels`) },
    { id: 'musculation_bench', name: translate(`resources.equipments.musculation_bench`) },
  ];

  const painAreaList = [
    { id: 'right_shoulder', name: translate(`resources.painAreas.right_shoulder`) },
    { id: 'left_shoulder', name: translate(`resources.painAreas.left_shoulder`) },
    { id: 'right_elbow', name: translate(`resources.painAreas.right_elbow`) },
    { id: 'left_elbow', name: translate(`resources.painAreas.left_elbow`) },
    { id: 'right_wrist', name: translate(`resources.painAreas.right_wrist`) },
    { id: 'left_wrist', name: translate(`resources.painAreas.left_wrist`) },
    { id: 'upper_back', name: translate(`resources.painAreas.upper_back`) },
    { id: 'lower_back', name: translate(`resources.painAreas.lower_back`) },
    { id: 'right_hip', name: translate(`resources.painAreas.right_hip`) },
    { id: 'left_hip', name: translate(`resources.painAreas.left_hip`) },
    { id: 'right_knee', name: translate(`resources.painAreas.right_knee`) },
    { id: 'left_knee', name: translate(`resources.painAreas.left_knee`) },
    { id: 'right_ankle', name: translate(`resources.painAreas.right_ankle`) },
    { id: 'left_ankle', name: translate(`resources.painAreas.left_ankle`) }
  ];

  const sectionList = [
    { id: 'Warmup', name: translate(`resources.sessions.warmup`) },
    { id: 'Exercises', name: translate(`resources.sessions.exercises`) },
    { id: 'Stretching', name: translate(`resources.sessions.stretching`) },
  ];

  const numberOfSessionToGenerateInBatchModeChange = (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setNumberOfSessionToGenerateInBatchMode(newValue);
    }
  };

  const StatusSymbol = (props) => {
    const status: CheckResultStatus = props.status;

    return status == CheckResultStatus.NoInfo ?
      <CircleIcon fontSize="inherit" />
      : status == CheckResultStatus.Pending ?
        <PendingIcon fontSize="inherit" />
        : status == CheckResultStatus.Error ?
          <ErrorIcon fontSize="inherit" color="error" />
          : status == CheckResultStatus.Success ?
            <CheckCircleIcon fontSize="inherit" color="success" />
            : null
  }

  const FormParameters = (props) => {
    const mode = useWatch({ name: 'mode' });
    const selectedLevelType = useWatch({ name: 'levelType' });
    const obesityCalculationMode = useWatch({ name: 'obesityCalculationMode' });
    const randomModule = useWatch({ name: 'randomize.module', defaultValue: true });
    const randomModuleLevel = useWatch({ name: 'randomize.moduleLevel', defaultValue: true });
    const randomEquipments = useWatch({ name: 'randomize.equipments', defaultValue: true });
    const randomPainAreas = useWatch({ name: 'randomize.painAreas', defaultValue: true });
    const randomGender = useWatch({ name: 'randomize.gender', defaultValue: true });
    const randomDateOfBirth = useWatch({ name: 'randomize.dateOfBirth', defaultValue: true });

    return mode == "user_id" ? (
      <Box sx={{ marginTop: "1em" }}>
        <TextInput
          source="params_user_id.id"
          label={translate("resources.users.id")}
          defaultValue=""
          type="string"
          disabled={loading}
          validate={required()}
          fullWidth
          helperText={`${translate(
            "resources.sessions.exampleAccount"
          )} ec1632ea-5e2f-409d-9e35-10cd46bdbc75 (bookbeodev)`}
        />
      </Box>
    ) : (
      <>
        {(mode == "full_single") &&
          (
            <>
              <Accordion sx={{ mr: -2, ml: -2, backgroundColor: 'rgba(102, 103, 171, 0.1)' }} defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography>
                    {translate("resources.sessions.userPathologyParams")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {program?.questionnaires.map((questionnaire) => {
                    return (
                      <Box>
                        <Typography sx={{ textDecoration: 'underline' }}>
                          {translate(`program.questionnaires.${questionnaire.id}`)}
                        </Typography>
                        {questionnaire.questions.map((question) => {
                          if (question.enumMultiple) {
                            return <SelectArrayInput
                              source={`params_full.programParams.${question.id}`}
                              label={translate(`program.params.${question.id}`)}
                              choices={question.enumMultiple.map((item) => { return { id: question.type == "number" ? Number(item) : item, name: translate(`program.params.${item}`, { _: item}) } })}
                              disabled={loading}
                              validate={required()}
                              fullWidth
                            />
                          }
                          if (question.enum) {
                            return <SelectInput
                              source={`params_full.programParams.${question.id}`}
                              label={translate(`program.params.${question.id}`)}
                              choices={question.enum.map((item) => { return { id: question.type == "number" ? Number(item) : item, name: translate(`program.params.${item}`, { _: item}) } })}
                              disabled={loading}
                              validate={required()}
                              fullWidth
                            />
                          }
                          // else (no multiple values)
                          switch (question.type) {
                            case "string":
                              return <TextInput
                                source={`params_full.programParams.${question.id}`}
                                label={translate(`program.params.${question.id}`)}
                                disabled={loading}
                                validate={required()}
                                fullWidth
                              />
                              case "number":
                                return <NumberInput
                                  source={`params_full.programParams.${question.id}`}
                                  label={translate(`program.params.${question.id}`)}
                                  disabled={loading}
                                  validate={required()}
                                  fullWidth
                                />
                          }
                        })}
                      </Box>
                    )
                  })}
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ mr: -2, ml: -2, backgroundColor: 'rgba(102, 103, 171, 0.1)' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography>
                    {translate("resources.sessions.userGeneralParams")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <NumberInput
                    source="params_full.sessionsCompleted"
                    label={translate("resources.sessions.sessionNumber")}
                    min="1"
                    defaultValue={1}
                    disabled={loading}
                    validate={required()}
                    fullWidth
                  />
                  <NumberInput
                    source="params_full.iterations"
                    label={translate("resources.sessions.iterations")}
                    min="1"
                    defaultValue={1}
                    disabled={loading}
                    validate={required()}
                    fullWidth
                  />
                  <SelectInput
                    source="params_full.module"
                    label={translate("resources.sessions.module")}
                    choices={moduleList}
                    defaultValue="balance"
                    disabled={loading}
                    validate={required()}
                    fullWidth
                  />
                  <SelectInput
                    source="params_full.moduleLevel"
                    label={translate("resources.sessions.level")}
                    choices={levelList}
                    defaultValue="2"
                    disabled={loading}
                    validate={required()}
                    fullWidth
                  />
                </AccordionDetails>
              </Accordion>
            </>)
        }
        {
          (mode != "full_batch") &&
          <Accordion sx={{ mr: -2, ml: -2, backgroundColor: 'rgba(102, 103, 171, 0.1)' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              <Typography>
                {translate("resources.sessions.userProfile")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <RadioButtonGroupInput
                source="params_full.gender"
                label={translate("resources.users.gender")}
                choices={genderList}
                defaultValue="female"
                disabled={loading}
                validate={required()}
              />
              <TextInput
                source="params_full.dateOfBirth"
                label={translate("resources.users.age")}
                type="number"
                defaultValue="50"
                disabled={loading}
                validate={required()}
                fullWidth
              />
              <TextInput
                source="height"
                label={translate("resources.users.height")}
                type="number"
                defaultValue="164"
                disabled={loading}
                validate={required()}
                fullWidth
              />
              <TextInput
                source="weight"
                label={translate("resources.users.weight")}
                type="number"
                defaultValue="67"
                disabled={loading}
                validate={required()}
                fullWidth
              />
              <SelectArrayInput
                source="params_full.equipments"
                label={translate("resources.sessions.equipments")}
                choices={equipementList}
                defaultValue={[]}
                disabled={loading}
                fullWidth
              />
              <SelectArrayInput
                source="params_full.painAreas"
                label={translate("resources.sessions.painAreas")}
                choices={painAreaList}
                defaultValue={[]}
                disabled={loading}
                fullWidth
              />
            </AccordionDetails>
          </Accordion>
        }
        {
          (mode == "full_batch") &&
          <>
            <Typography color="primary" component="p">
              {
                mode == "full_batch" ?
                  "Batch : " + numberOfSessionToGenerateInBatchMode + " séances testées."
                  : "Un séance testée."
              }
            </Typography>
            <Slider
              defaultValue={numberOfSessionToGenerateInBatchMode}
              onChangeCommitted={numberOfSessionToGenerateInBatchModeChange}
              step={10}
              min={10}
              max={250}
              valueLabelDisplay="auto"
            />
            <Typography component="p">
              {translate("resources.sessions.randomization")}
            </Typography>

            <BooleanInput
              source="randomize.module"
              label={translate("resources.sessions.module")}
              defaultValue={true}
              disabled={loading}
              fullWidth
            />
            {!randomModule &&
              <SelectInput
                source="params_full.module"
                label={translate("resources.sessions.module")}
                choices={moduleList}
                defaultValue="balance"
                disabled={loading}
                validate={required()}
                fullWidth
              />
            }
            <BooleanInput
              source="randomize.moduleLevel"
              label={translate("resources.sessions.level")}
              defaultValue={true}
              disabled={loading}
              fullWidth
            />
            {!randomModuleLevel &&
              <SelectInput
                source="params_full.moduleLevel"
                label={translate("resources.sessions.level")}
                choices={levelList}
                defaultValue="2"
                disabled={loading}
                validate={required()}
                fullWidth
              />
            }

            <BooleanInput
              source="randomize.equipments"
              label={translate("resources.sessions.equipments")}
              defaultValue={true}
              disabled={loading}
              fullWidth
            />
            {!randomEquipments &&
              <SelectArrayInput
                source="params_full.equipments"
                label={translate("resources.sessions.equipments")}
                choices={equipementList}
                defaultValue={[]}
                disabled={loading}
                fullWidth
              />
            }

            <BooleanInput
              source="randomize.painAreas"
              label={translate("resources.sessions.painAreas")}
              defaultValue={true}
              disabled={loading}
              fullWidth
            />
            {!randomPainAreas &&
              <SelectArrayInput
                source="params_full.painAreas"
                label={translate("resources.sessions.painAreas")}
                choices={painAreaList}
                defaultValue={[]}
                disabled={loading}
                fullWidth
              />}

            <BooleanInput
              source="randomize.gender"
              label={translate("resources.users.gender")}
              defaultValue={true}
              disabled={loading}
              fullWidth
            />
            {!randomGender &&
              <RadioButtonGroupInput
                source="params_full.gender"
                label={translate("resources.users.gender")}
                choices={genderList}
                defaultValue="female"
                disabled={loading}
                validate={required()}
              />
            }

            <BooleanInput
              source="randomize.dateOfBirth"
              label={translate("resources.users.age")}
              defaultValue={true}
              disabled={loading}
              fullWidth
            />
            {!randomDateOfBirth &&
              <TextInput
                source="params_full.dateOfBirth"
                label={translate("resources.users.age")}
                type="number"
                defaultValue="50"
                disabled={loading}
                validate={required()}
                fullWidth
              />
            }
          </>
        }

      </>
    );
  };

  const BatchSessionsSummary = (props) => {
    let sessions = props.sessions;
    let numErrors = sessions.filter((session) => session.status == CheckResultStatus.Error).length;
    let numSuccess = sessions.filter((session) => session.status == CheckResultStatus.Success).length;
    return (
      <Card>
        <CardContent>
          <Typography variant="h5">
            <ErrorIcon fontSize="inherit" color="error" /> {translate('resources.misc.error', { smart_count: numErrors })} : {numErrors + " (" + Math.round(numErrors / props.sessions.length * 100) + "%)"}
          </Typography>
          <Typography variant="h5">
            <ErrorIcon fontSize="inherit" color="success" /> {translate('resources.misc.success', { smart_count: numSuccess })} : {numSuccess}
          </Typography>
        </CardContent>
      </Card>
    )
  }

  return (
    <Box
      display="flex"
      sx={{
        mt: 2
      }}
    >
      <Title title={translate(`resources.sessions.generateSessions`)} />
      <Card
        sx={{
          display: { xs: 'none', md: 'block' },
          order: -1,
          width: '15em',
          mr: 2,
          alignSelf: 'flex-start',
        }}
      >
        <CardContent sx={{ pt: 1 }}>
          <Form onSubmit={submit} noValidate>
            <Box sx={{ marginTop: '1em' }}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={loading}
                fullWidth
              >
                {loading && (
                  <CircularProgress size={25} thickness={2} />
                )}
                {translate('resources.sessions.execute')}
                {loading && numberOfLoadedSessions > 1 && <> ({Math.floor(numberOfLoadedSessions / numberOfSessionToGenerateInBatchMode * 100)}%)</>}
              </Button>
            </Box>
            <RadioButtonGroupInput
              source="mode"
              choices={[
                { id: 'user_id', name: translate('resources.sessions.user') },
                { id: 'full_single', name: translate('resources.sessions.full') },
                { id: 'full_batch', name: translate('resources.sessions.full_batch') }
              ]}
              disabled={loading}
              required
            />
            <FormParameters />

          </Form>
        </CardContent>
      </Card>
      <Box width={isSmall ? 'auto' : 'calc(100% - 16em)'}>

        {!loading &&
          <>
            {sessions.length == 1 &&
              <Box sx={{ mt: 2, mb: 2 }}>
                <Grid
                  container
                  spacing={24}
                >
                  <Grid sm={12} md={8} item>
                    {typeof (sessions[0].result.components) !== 'undefined' ?
                      <Box>
                        <Typography variant="h5" color="primary" component="p">
                          <TimerIcon fontSize="inherit" /> {moment.utc(moment.duration({ seconds: sessions[0].result.duration }).as('milliseconds')).format('HH[:]mm[:]ss')}
                        </Typography>
                        <Typography>
                          <PersonIcon fontSize="inherit" /> {sessions[0].result.author}
                        </Typography>
                        {sessions[0].result.module && (
                          <Typography>
                            <ExtensionIcon fontSize="inherit" /> {translate(`resources.modules.${sessions[0].result.module}`)}
                          </Typography>
                        )}
                        {
                          sessions[0].result.name != "Fit Session" ?
                            <Typography>
                              <WysiwygIcon fontSize="inherit" /> {sessions[0].result.name}
                            </Typography>
                            : null
                        }
                        {
                          typeof sessions[0].params.painAreas != "undefined" && sessions[0].params.painAreas.length ?
                            <Typography>
                              <ElderlyIcon fontSize="inherit" /> {sessions[0].params.painAreas.map(item => translate(`resources.painAreas.${item}`)).join(', ')}
                            </Typography>
                            : null
                        }
                      </Box>
                      : null}
                  </Grid>
                  <Grid sm={12} md={4} justifyContent="flex-end" item>
                    <Typography component="div" align="right" style={{ fontSize: 40 }}>
                      <StatusSymbol status={sessions[0].status} />
                    </Typography>
                    {sessions[0].result ?
                      <RawSessionOutputDialog session={sessions[0].result} />
                      : null}
                  </Grid>
                </Grid>
                {sessions[0].status == CheckResultStatus.Error && sessions[0].errorMessages.length ?
                  <Alert severity="error">
                    <AlertTitle>{translate('resources.misc.error', { smart_count: sessions[0].errorMessages.length })}</AlertTitle>
                    <ul>
                      {sessions[0].errorMessages
                        .map((item) => {
                          return (
                            <li>{item}</li>
                          )
                        })
                      }
                    </ul>
                  </Alert>
                  : null}
                {typeof (sessions[0].result.components) !== 'undefined' ?
                  <Grid container spacing={2} sx={{ mt: 2 }}>
                    {sectionList.map((section, i) => {
                      return <Grid key={i} sm={12} md={4} item>
                        <Typography
                          align="center"
                          variant="button"
                          component="p"
                        >
                          {section.name}
                        </Typography>
                        {sessions[0].result.components
                          .filter((element) => element.section == section.id)
                          .map((element, i, components) => {
                            return (
                              <Box key={i}>
                                <Typography variant="body2" component="div" align="center">
                                  {
                                    exercisesVariantsList[element.exerciseVariantId] && exercisesVariantsList[element.exerciseVariantId].variants.length == 2 && (i > 0) && components[i - 1].exerciseVariantId == exercisesVariantsList[element.exerciseVariantId].variants[0].id ?
                                      <Chip icon={<CompareArrowsIcon />} label={`${element.pause} s ${translate('resources.sessions.switchSide')}`} color="warning" />
                                      : <Chip icon={<TimerIcon />} label={`${element.pause} s`} />

                                  }

                                </Typography>
                                <Card sx={{ mb: 1, mt: 1 }}>
                                  <CardContent className="session-exercise-details">
                                    <Typography variant="body2" component="p">
                                      <strong>
                                        {element.name} ({exercisesVariantsList[element.exerciseVariantId].categories.map(e => e.id)})
                                      </strong>
                                      <br />
                                      {element.exerciseVariantId}
                                      <br />
                                      {exercisesVariantsList[element.exerciseVariantId].painAreas.join(', ')}
                                    </Typography>
                                    <img
                                      src={`/gif/${element.exerciseVariantId}.gif`}
                                      className="exercise-image"
                                      style={{ width: "100%", aspectRatio: "1/1" }}
                                    />
                                    <Chip
                                      className="session-exercise-duration"
                                      label={`${element.duration} s`}
                                      color="primary"
                                    />
                                  </CardContent>
                                </Card>
                              </Box>
                            );
                          })}
                      </Grid>;
                    })}
                  </Grid>
                  : null}
              </Box>
            }
            {sessions.length > 1 &&
              <>
                <Typography>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={showValidSessions} onChange={e => setShowValidSessions(e.target.checked)} />} label={translate('resources.sessionGenerator.showValidSessions')} />
                    <FormControlLabel control={<Checkbox checked={showExercisesSectionList} onChange={e => setShowExercisesSectionList(e.target.checked)} />} label={translate('resources.sessionGenerator.showExercisesSectionList')} />

                  </FormGroup>
                </Typography>
                <BatchSessionsSummary sessions={sessions} />
                {sessions.map((session) => {
                  if (!showValidSessions && session.status == CheckResultStatus.Success) {
                    return null;
                  }
                  return <Box sx={{ borderBottom: 1 }}>
                    <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                      <Grid key={0} sm={4} md={2} item>
                        <>
                          {
                            session.result.name != "Fit Session" ?
                              <Typography>
                                <WysiwygIcon fontSize="inherit" /> {session.result.name}
                              </Typography>
                              : null
                          }
                          <Typography>{translate("resources.sessions.iterations")} : {session.params.iterations}</Typography>
                          <Typography>{translate("resources.sessions.module")} : {translate(`resources.modules.${session.params.module}`)}</Typography>
                          <Typography>{translate("resources.sessions.level")} : {session.params.moduleLevel}</Typography>

                          <Typography>
                            {translate("resources.sessions.equipments")} :&nbsp;
                            {typeof sessions[0].params.equipments != "undefined" && session.params.equipments.length ?
                              <ul>
                                {session.params.equipments.map((equipment) => (<li>{translate(`resources.equipments.${equipment}`)}</li>))}
                              </ul>
                              : translate("resources.misc.none")
                            }
                          </Typography>
                          <Typography>{translate("resources.users.gender")} : {translate(`resources.users.${session.params.gender}`)}</Typography>
                          <Typography>{translate("resources.users.age")} : {moment().diff(session.params.dateOfBirth, 'years')}</Typography>
                        </>
                      </Grid>
                      <Grid key={1} sm={4} md={8} item>
                        <>
                          <Typography color="primary" component="p">
                            <TimerIcon fontSize="inherit" /> {moment.utc(moment.duration({ seconds: session.result.duration }).as('milliseconds')).format('HH[:]mm[:]ss')}
                            {session.result.module && (
                              <>&nbsp;/&nbsp;<ExtensionIcon fontSize="inherit" /> {translate(`resources.modules.${session.result.module}`)}</>
                            )}
                            &nbsp;/&nbsp;<FitnessCenterIcon fontSize="inherit" /> {(session.result.equipments && session.result.equipments.length) ? session.result.equipments.map(item => translate(`resources.equipments.${item}`)).join(', ') : <i>{translate('resources.misc.none')}</i>}
                            {
                              session.params.painAreas && session.params.painAreas.length ?
                                <>
                                  &nbsp;/&nbsp;
                                  <ElderlyIcon fontSize="inherit" /> {session.params.painAreas.map(item => translate(`resources.painAreas.${item}`)).join(', ')}
                                </>
                                : null
                            }
                          </Typography>
                          {session.status == CheckResultStatus.Error && session.errorMessages.length ?
                            <Alert severity="error">
                              <AlertTitle>{translate('resources.misc.error', { smart_count: session.errorMessages.length })}</AlertTitle>
                              <ul>
                                {session.errorMessages
                                  .map((item) => {
                                    return (
                                      <li>{item}</li>
                                    )
                                  })
                                }
                              </ul>
                            </Alert>
                            : null}
                          {showExercisesSectionList ?
                            (<ol>
                              {session.result.components.map(component => {
                                return component.section == 'Exercises' ?
                                  (<li>
                                    <Chip
                                      avatar={<Avatar>
                                        {exercisesVariantsList[component.exerciseVariantId].categories.map((category) => category.id)}
                                      </Avatar>}
                                      label={component.exerciseVariantId + ' - ' + exercisesVariantsList[component.exerciseVariantId].intensity + exercisesVariantsList[component.exerciseVariantId].bodyParts.map(bodyPart => { return " " + bodyPart.id })}
                                      variant="outlined"
                                    />
                                  </li>)
                                  : null;
                              })}
                            </ol>)

                            : null}
                        </>
                      </Grid>
                      <Grid key={2} sm={2} md={2} item>
                        <>
                          <Typography component="div" align="right" style={{ fontSize: 40 }}>
                            <StatusSymbol status={session.status} />
                          </Typography>
                          {session.result ?
                            <RawSessionOutputDialog session={session.result} />
                            : null}
                        </>
                      </Grid>
                    </Grid>
                  </Box>
                })}
              </>
            }
          </>
        }
      </Box>
    </Box>
  );
};

export default SessionGenerator;
