import React from "react";
import {
  DateTimeInput,
  Edit,
  DateField,
  FunctionField,
  required,
  FormDataConsumer,
  BulkUpdateButton,
  SimpleForm,
  SimpleShowLayout,
  BooleanInput,
  TextInput,
  TextField,
  SelectInput,
  NumberInput,
  Toolbar,
  SaveButton,
  Title,
} from "react-admin";
import { useTranslate } from "ra-core";
import { useParams } from 'react-router-dom';
import {
  Box,
} from '@mui/material';
import Grid from '@mui/material/Grid';

const validateName = [];
const validateRefKey = [];

const CustomToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
    <BulkUpdateButton
      label="Révoquer"
      data={{ status: "revoked" }}
      sx={{ color: "red" }}
    />
  </Toolbar>
);

export const CodeProgramEdit = (props) => {
  const translate = useTranslate();

  const PageTitle = () => {
    const { id } = useParams();
    return id ? (
      <Title title={translate('resources.codes.title', {
        id: id,
      })} />
    ) : null;
  };

  return (
    <Edit
      {...props}
      resource="AlixMedInviteCodes"
      title={" "}
    >
      <>
        <PageTitle />
        <SimpleForm toolbar={<CustomToolbar />}>
          <Grid container>
            <Grid xs={8} item>
              <Box sx={{ px: 2 }}>
                <TextInput label="Name" source="name" fullWidth validate={required()} />
                <FormDataConsumer>
                  {({ formData, ...rest }) => formData.refKey ?
                    <TextInput label={translate=(`resources.codes.refKey`)} source="refKey" fullWidth disabled />
                    :
                    <TextInput label="refKey" source="refKey" fullWidth />
                  }
                </FormDataConsumer>
                <BooleanInput label={translate(`resources.codes.edit.setExpiresAtDate`)} source="withExpiration" />
                <FormDataConsumer>
                  {({ formData, ...rest }) => formData.withExpiration &&
                    <DateTimeInput {...rest} label={translate(`resources.codes.expiresAt`)} source="expiresAt" fullWidth />
                  }
                </FormDataConsumer>
                <BooleanInput label={translate(`resources.codes.use.count`)} source="withCounts" />
                <FormDataConsumer>
                  {({ formData, ...rest }) => formData.withCounts &&
                    <NumberInput {...rest} label={translate(`resources.codes.edit.setMaxUses`)} source="maxUses" fullWidth />
                  }
                </FormDataConsumer>
                <BooleanInput label={translate(`resources.codes.validation`)} source="withUserValidation" />
                <FormDataConsumer>
                  {({ formData, ...rest }) => formData.withUserValidation &&
                    <SelectInput {...rest}
                      label={translate(`resources.codes.validationMethod`)}
                      source="userValidationMethod"
                      fullWidth
                      resettable
                      choices={[
                        { id: 0, name: 'Option 0' },
                        { id: 1, name: 'Option 1' },
                        { id: 2, name: 'Option 2' },
                      ]} />
                  }
                </FormDataConsumer>
              </Box>
            </Grid>
            <Grid xs={4} item>
              <Box sx={{ px: 2 }}>
                <SimpleShowLayout>
                  <TextField label={translate(`resources.codes.id`)} source="id" fullWidth />
                  <FunctionField
                    label={translate(`resources.codes.status`)}
                    render={
                      record => translate(`resources.codes.${record.codeStatus}`)
                    }
                  />
                  <TextField label={translate(`resources.codes.uses`)} source="uses" fullWidth emptyText="-" />
                  <DateField label={translate(`resources.codes.createdAt`)} source="createdAt" fullWidth />
                </SimpleShowLayout>
              </Box>
            </Grid>
          </Grid>
        </SimpleForm>
      </>
    </Edit>
  );
}

export default CodeProgramEdit;