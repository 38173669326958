import React, { useState } from "react";
import {
  List,
  useGetOne,
  useRedirect,
  Title,
} from "react-admin";
import { useTranslate } from "ra-core";
import { useParams } from 'react-router-dom';
import { Box, Paper, Typography, Stack, CircularProgress } from '@mui/material';
import { theme } from '../layout/themes';
import users from '../users';
import inviteCodes from '../inviteCodes';

const MenuBlocks = (props) => {
  const redirect = useRedirect();
  const translate = useTranslate();
  const program = props.program

  const handleClick = (path) => {
    redirect(path);
  }


  console.log(theme);
  const items = [
    { id: 1, title: translate(`pos.menu.codes`), Icon: inviteCodes.icon, path: `/${program.id}/invite-codes` },
    { id: 2, title: translate(`pos.menu.users`), Icon: users.icon, path: `/${program.id}/users` },
  ];


  return (
    <Box display="flex" flexWrap="wrap" alignContent="flex-start"> 
      {items.map(({ id, title, Icon, path }) => (
        <Box key={id} m={1} onClick={() => redirect(path)} style={{ cursor: 'pointer' }}>
          <Paper
            style={{
              width: '200px',
              height: '200px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.secondary.contrastText
            }}>
            <Icon style={{ marginBottom: '10px' }} />
            <Typography variant="h5">{title}</Typography>
          </Paper>
        </Box>
      ))}
    </Box>
  )
}


export const ProgramShow = (props) => {
  const { programId } = useParams();
  const { data, isLoading } = useGetOne(
    'AlixMedPrograms',
    { id: programId },
    { onError: () => <div>Error</div> }
  );
  if (isLoading) { return <CircularProgress size={25} thickness={2} />; }


  const ScreenTitle = () => {
    const translate = useTranslate();
    return (
      <Title title={translate(`pos.programShowTitle`, { programName: data.displayName })} />
    );
  };

  return (
    <>
      <ScreenTitle />
      <Stack spacing={2} direction="column" sx={{ mx: 4 }}>
        {data ?
          <h1>{data.displayName}</h1>
          : null}
        <MenuBlocks program={data} />
      </Stack>
    </>
  );
};

export default ProgramShow;