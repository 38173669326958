import { API } from "aws-amplify";
import { useEffect, useState } from "react";

export const useFetchData: any = (path) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(undefined);
  useEffect(() => {
    const callApi = async () => {
      const response = await API.get("alixMedRestApi", path, {}).catch((e) => {
        setError(e);
        console.log("error useFetchData", e);
      });
      if (!response) {
        setError(true);
      } else {
        setData(response.data);
      }
    };
    setLoading(true);
    callApi();
    setLoading(false);
  }, [path]);
  return {
    error: error,
    loading: loading,
    data: data,
  };
};

export const getAsyncFetchData = async (path, params = {}) => {
  let error = null;
  let response: any = {};
  response = await API.get("alixMedRestApi", path, params).catch((e) => {
    console.log("error useFetchData", e);
    error = e;
  });
  return {
    data: response?.data,
    error: error,
  };
};

export const postAsyncData = async (path, params = {}) => {
  let error: any = undefined;
  let response: any = undefined;
  response = await API.post("alixMedRestApi", path, { body: params }).catch(
    (e) => {
      console.log("error useFetchData", e);
      error = e;
    }
  );
  return {
    data: response,
    error: error,
  };
};

export const deleteAsyncData = async (path, params = {}) => {
  let error: any = undefined;
  let response: any = undefined;
  response = await API.del("alixMedRestApi", path, { body: params }).catch(
    (e) => {
      console.log("error useFetchData", e);
      error = e;
    }
  );
  return {
    data: response,
    error: error,
  };
};
